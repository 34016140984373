import {differenceInSeconds, subDays} from "date-fns";
import {OrderResponse} from "./models";
import {
  CANCELLED_ORDERS_UPPER_BOUND_STATUS_ID,
  COMPLETED_ORDERS_LOWER_BOUND_STATUS_ID, OrderStatusIds,
  OrderStatusGroupIds
} from "./constants";
import {
  getDateInServerFormat, getDateTimeTuple,
  parseServerDate
} from "../../common/utils/dateTimeHelpers";

export enum OrderDeliveryTypes {
  /** Доставка */
  Courier = 1,
  /** Самовывоз */
  Self = 2,
}

/**
 * Названия типов доставки в виде ключей перевода (translation.orders)
 */
export const orderDeliveryTypeNameKeys: Record<OrderDeliveryTypes, string> = {
  [OrderDeliveryTypes.Courier]: "deliveryCourier",
  [OrderDeliveryTypes.Self]: "deliverySelf",
}

export const orderDeliveryTypeNameTimeKeys: Record<OrderDeliveryTypes, string> = {
  [OrderDeliveryTypes.Courier]: "deliveryCourierAtTime",
  [OrderDeliveryTypes.Self]: "deliverySelfAtTime",
}

export enum OrderPaymentTypes {
  /** Наличные */
  Cash = 1,
  /** Картой курьеру */
  ToCouriersCard = 2,
  /** Онлайн-оплата */
  Online = 5,
}

/**
 * Названия типов оплаты
 */
export const orderPaymentTypeNameKeys = {
  [OrderPaymentTypes.Cash]: "paymentCash",
  [OrderPaymentTypes.ToCouriersCard]: "paymentCouriersCard",
  [OrderPaymentTypes.Online]: "paymentOnline",
}

/**
 * true, если по параметрам заказа для него можно показывать таймер обратного отсчета
 */
export const shouldDisplayOrderTimerString = ({
  status,
  isEmenuCourier,
  deliveryDateTime,
}: Pick<OrderResponse, 'status' | 'isEmenuCourier' | 'deliveryDateTime'>) =>
  status.id === OrderStatusIds.New
  && isEmenuCourier
  && !deliveryDateTime

/**
 * Вычисление, сколько времени осталось до точки времени input + 5 минут.
 * @param input - дата, от которой начинается отсчет
 * @param referenceDate - дата, которая считается за время сейчас
 */
export const getDiffInSeconds = (
  input: string,
  referenceDate: Date,
): number => {
  
  const inputInMS = parseServerDate(input);
  const diff = differenceInSeconds(referenceDate, inputInMS);
  
  /**
   * 300 секунд - 5 минут, максимально возможный результат
   */
  return Math.max(300 - diff, 0);
}

// названия корректные, но пока не используются
// export const orderStatusNames: Record<OrderStatusIds, string> = {
//   [OrderStatusIds.New]: 'Новые',
//   [OrderStatusIds.StartCooking]: 'Начать готовку',
// }

export const orderStatusGroupNames: Record<OrderStatusGroupIds, string> = {
  [OrderStatusGroupIds.Cancelled]: 'Отмененные',
  [OrderStatusGroupIds.TimeForProcessingExpired]: 'Время на обработку истекло',
  // [OrderStatusGroupIds.InProgress]: 'В работе',
  [OrderStatusGroupIds.Delivered]: 'Доставленные',
}

/**
 * true, если заказ отменен или уже выполнен
 */
export const isOrderNotInProgress = (
  {status: {id}}: OrderResponse,
): boolean =>
  id <= CANCELLED_ORDERS_UPPER_BOUND_STATUS_ID
  || id >= COMPLETED_ORDERS_LOWER_BOUND_STATUS_ID

/**
 * Новый ли заказ
 */
export const isOrderNew = ({status: {id}}: Pick<OrderResponse, 'status'>) => id < 10;

/**
 * Принят ли заказ
 */
export const isOrderAccepted = ({status: {id}}: Pick<OrderResponse, 'status'>) =>
  [10, 11, 12, 14, 15, 16].includes(id);

/**
 * Готов ли заказ
 */
export const isOrderCompleted = ({status: {id}}: Pick<OrderResponse, 'status'>) =>
  id === 13 || id >= 17;

/**
 * Требует ли заказ внимания сейчас: да, если он в статусах Новый или Начать готовку
 */
export const doesOrderNeedAttention = ({status: {id}}: Pick<OrderResponse, 'status'>) =>
  id === OrderStatusIds.New || id === OrderStatusIds.StartCooking;

/**
 * Получить название события замены заказа
 */
export const getOrderReplaceEventName = (id: string | number) => `replace-order-${id}`;

/**
 * Получить сведения о важных временных рамках заказа
 */
export const getOrderTimingInfo = ({
  isEmenuCourier,
  deliveryTypeId,
  courierArrivalDateTime,
  deliveryDateTime,
}: OrderResponse) => {
  if (isEmenuCourier) {
    const displayedCourierArrivalDateTime = getDateTimeTuple(
      courierArrivalDateTime,
      {withoutYear: true}
    )?.join(' ') ?? '';

    return {
      labelKey: displayedCourierArrivalDateTime
        ? 'prepareOrderAtTime'
        : 'prepareOrder',
      value: displayedCourierArrivalDateTime,
    };
  }

  const displayedDeliveryDateTime = getDateTimeTuple(
    deliveryDateTime,
    {withoutYear: true},
  )?.join(' ') ?? '';

  return {
    labelKey: displayedDeliveryDateTime
      ? orderDeliveryTypeNameTimeKeys[deliveryTypeId]
      : orderDeliveryTypeNameKeys[deliveryTypeId],
    value: displayedDeliveryDateTime,
  }
}

export enum ArchiveFilterDateTimeValues {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_WEEK = 'lastWeek',
  LAST_15_DAYS = 'lastDays_15',
  LAST_30_DAYS = 'lastDays_30',
  LAST_6_MONTHS = 'lastMonths_6',
  CUSTOM = 'custom',
}

type GFDSAParams = {
  daysAgo: number;
}

/**
 * Получить дату некоторое время назад в формате бэк-энда.
 * Пока поддерживается только несколько дней назад, потому что только это используется в нескольких компонентах.
 */
export const getFormattedDateSometimeAgo = ({
  daysAgo,
}: GFDSAParams) => {
  return getDateInServerFormat(subDays(new Date(), daysAgo).setHours(0, 0, 0))
}
